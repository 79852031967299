import { css } from '@emotion/react'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import {
  color,
  definitions,
  font,
  weight
} from 'driverama-core/styles/variables'
import { forwardRef, ReactNode } from 'react'
import {
  SCheckbox,
  SCheckboxWrapper,
  SInput,
  SLabel,
  SPartialCheck
} from './Checkbox.styled'

type Props = {
  name: string
  checked: boolean
  partialChecked?: boolean
  onChange: (value: boolean) => void
  label?: ReactNode
  className?: string
  testId?: string
  color?: keyof typeof definitions['c']
  error?: boolean
}

export const Checkbox = forwardRef<HTMLSpanElement, Props>(
  (props: Props, ref) => {
    const checkboxColor = props.error
      ? color('warning')
      : props.checked
      ? color('night-l-100')
      : color('night-l-650')

    return (
      <SCheckboxWrapper css={{ color: checkboxColor }} ref={ref}>
        <SInput
          id={props.name}
          type="checkbox"
          checked={props.checked}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            props.onChange(e.target.checked)
          }}
          data-testid={props.testId}
        />

        <SLabel htmlFor={props.name} className={props.className}>
          {props.partialChecked && <SPartialCheck />}

          <SCheckbox>
            <svg
              width="14"
              height="12"
              viewBox="0 0 14 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 5.95389L5 10.1077L13 1.80005"
                stroke="currentColor"
                strokeWidth="0"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </SCheckbox>

          <Spacer size={3} />

          <span
            css={css`
              font-family: ${font('text')};
              font-size: ${size(4)};
              font-weight: ${weight('regular')};
              line-height: 24px;
              color: ${props.error ? color('warning') : color('night-text')};

              @media ${media.lte('tablet')} {
                font-size: ${size(3.5)};
              }
            `}
          >
            {props?.label}
          </span>
        </SLabel>
      </SCheckboxWrapper>
    )
  }
)
