import styled from '@emotion/styled'
import { size } from 'driverama-core/styles/spacing'
import { color, radius, time } from 'driverama-core/styles/variables'

export const SCheckboxWrapper = styled.span`
  min-height: ${size(6)};
`

export const SCheckbox = styled.span`
  display: inline-block;
  vertical-align: middle;
  flex-shrink: 0;

  width: ${size(6)};
  height: ${size(6)};

  border-radius: ${radius('input')};
  border: 2px solid currentColor;
  transition: all ${time('control')};

  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    --stroke-length: 17.299942016601562;

    stroke: ${color('night-l-100')};
    transition: all ${time('control')};

    stroke-dasharray: var(--stroke-length);
    stroke-dashoffset: var(--stroke-length);
  }
`

export const SLabel = styled.label`
  display: flex;
  align-items: center;

  margin: 0;
  user-select: none;
  cursor: pointer;
`

export const SInput = styled.input`
  display: none;

  &:checked + ${SLabel} {
    ${SCheckbox} {
      svg {
        stroke-dashoffset: 0;

        > path {
          stroke-width: 2px;
        }
      }
    }
  }
`

export const SPartialCheck = styled.span`
  position: relative;

  &::before {
    content: '';
    width: 15px;
    height: 2px;
    background: ${color('night-l-100')};
    display: block;
    position: absolute;
    border-radius: 2px;
    top: -1px;
    left: ${size(1)};
  }
`
